const categories = [
  {
    name: "Smartfon",
    uriId: "smartphone",
  },
  {
    name: "Laptop",
    uriId: "notebook",
  },
  {
    name: "Tablet",
    uriId: "tablet",
  },
  {
    name: "Konsola",
    uriId: "console",
  },
];

export default categories;
